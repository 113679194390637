const topMain = () => {
  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {



    let secMain = document.getElementById('jsSecMain');
    let imgWrap = secMain.querySelector('.js-img-wrap');
    let copyWrap = secMain.querySelector('.js-copy-wrap');
    let copyBoxs = copyWrap.querySelectorAll('.js-copy-box');
    let rhombusWrap = secMain.querySelector('.js-rhombus-wrap');


    setTimeout(() => {
      imgWrap.classList.add('act')
    }, 1000);

    setTimeout(() => {
      copyBoxs[0].classList.add('act')
    }, 2500);

    setTimeout(() => {
      copyBoxs[1].classList.add('act')
    }, 3000);

    setTimeout(() => {
      rhombusWrap.classList.add('act')
    }, 3800);



  }
}
export default topMain;