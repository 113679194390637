const topRhombus = (() => {
  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {



    const elem = document.querySelectorAll('.js-rhombus');
    if (elem.length > 0) {

      for (let i = 0; i < elem.length; i++) {

        let paths = elem[i].querySelectorAll('path');
        // console.log(paths);

        for (let i = 0; i < paths.length; i++) {

          let random = Math.floor(Math.random() * 11);
          // console.log(random);

          gsap.set(paths[i], {
            autoAlpha: 0,
            filter: 'blur(4px)',
          });

          gsap.to(paths[i], {
            autoAlpha: 1,
            filter: 'blur(0px)',
            duration: 3,
            // delay: random * 0.5,
            delay: i * 0.8,
            repeat: -1,
            repeatDelay: .0, // 0.5秒後に繰り返す
            yoyo: true,
            ease: "power3.inOut",
          });

        }

      }

    }



  }
})();
export default topRhombus;