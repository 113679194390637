const comFade = (() => {

  const fadeElem = document.querySelectorAll('.js-com-fade');
  if (fadeElem.length > 0) {



    for (let i = 0; i < fadeElem.length; i++) {

      let tl = gsap.timeline({
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: fadeElem[i],
          start: "0% 80%",
          onEnter: () => fadeElem[i].classList.add('act'),
          // markers: true,
        },
      })


      let target = document.querySelector('body');
      let observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          tl.scrollTrigger.refresh()
        });
      });
      observer.observe(target);

    }



  }

})();
export default comFade;